@use "../../public/styles/variables.scss";

.cookies-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: variables.$neutral_03;
  color: variables.$text_02;
  z-index: 1000;
  display: none;

  &.active {
    display: block;
  }

  .text-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 625px;
    gap: 24px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 16px;

    a {
      color: variables.$link;
    }

    .button {
      padding: 4px 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      border-color: variables.$link;
    }
  }
}

@media only screen and (max-width: 768px) {
  body {
    > .cookies-container .text-wrapper {
      gap: 8px;
      padding: 8px 16px;
      line-height: 16px;

      img {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  body {
    > .cookies-container .text-wrapper {
      font-size: 12px;
      line-height: 16px;
    }
  }
}